<template>
  <svg width="252" height="251" viewBox="0 0 252 251" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6029_8115)">
      <path
        d="M125.988 250.304C195.107 250.304 251.14 194.272 251.14 125.152C251.14 56.0325 195.107 0 125.988 0C56.8684 0 0.835938 56.0325 0.835938 125.152C0.835938 194.272 56.8684 250.304 125.988 250.304Z"
        fill="#80BAFF"
        fill-opacity="0.8"
      />
      <path
        d="M140 98H53C52.4477 98 52 98.4477 52 99V162C52 162.552 52.4477 163 53 163H140C140.552 163 141 162.552 141 162V99C141 98.4477 140.552 98 140 98Z"
        fill="#E6EBFF"
      />
      <path
        d="M157 55H70C69.4477 55 69 55.4477 69 56V97C69 97.5523 69.4477 98 70 98H157C157.552 98 158 97.5523 158 97V56C158 55.4477 157.552 55 157 55Z"
        fill="#E6EBFF"
      />
      <path d="M136 66.667L161 69V65L136 66.667Z" fill="#94C3FA" />
      <path
        d="M173.601 70.7968C171.61 78.8088 175.165 86.5888 181.542 88.1738C187.92 89.7578 194.703 84.5478 196.694 76.5358C198.685 68.5228 197.19 52.4538 190.813 50.8688C184.436 49.2838 175.592 62.7848 173.601 70.7968ZM125.142 89.1878C129.762 97.1888 138.652 100.705 144.999 97.0398C151.347 93.3748 152.747 83.9188 148.128 75.9178C143.509 67.9168 129.839 56.1228 123.492 59.7878C117.144 63.4518 120.523 81.1878 125.142 89.1878ZM79.8892 133.393C81.6062 136.367 84.9102 137.674 87.2692 136.312C89.6292 134.95 90.1492 131.435 88.4322 128.461C86.7152 125.487 81.6353 121.104 79.2753 122.466C76.9163 123.828 78.1722 130.42 79.8892 133.393Z"
        fill="#00008D"
      />
      <path
        d="M202.95 117.151C202.95 90.558 185.5 69 163.975 69C142.45 69 125 90.558 125 117.151V117.364C125.004 118.64 125.077 119.851 125.211 121H101.5C85.208 121 72 134.208 72 150.5C72 153.169 72.355 155.756 73.019 158.215C72.3468 159.543 71.9977 161.011 72 162.5V180.5C72 183.02 73.0009 185.436 74.7825 187.218C76.5641 188.999 78.9805 190 81.5 190C84.0196 190 86.4359 188.999 88.2175 187.218C89.9991 185.436 91 183.02 91 180.5V178.077C93.2539 178.935 95.605 179.512 98 179.795V180.5C98 183.02 99.0009 185.436 100.782 187.218C102.564 188.999 104.98 190 107.5 190C110.02 190 112.436 188.999 114.218 187.218C115.999 185.436 117 183.02 117 180.5V180H135V180.5C135 183.02 136.001 185.436 137.783 187.218C139.564 188.999 141.98 190 144.5 190C147.02 190 149.436 188.999 151.218 187.218C152.999 185.436 154 183.02 154 180.5V180H169.5C170.342 180 171.176 179.965 172 179.896V180.5C172 183.02 173.001 185.436 174.783 187.218C176.564 188.999 178.98 190 181.5 190C184.02 190 186.436 188.999 188.218 187.218C189.999 185.436 191 183.02 191 180.5V170.699C196.148 165.235 199.01 158.008 199 150.5C199 145.51 197.761 140.809 195.573 136.688C199.499 133.498 201.526 128.822 202.417 124.452C202.703 123.048 202.873 121.675 202.95 120.393V117.151Z"
        fill="#00008D"
      />
      <path
        d="M73.019 158.215C72.3406 155.7 71.998 153.105 72 150.5V162.5C72 160.958 72.367 159.502 73.019 158.215ZM195.573 136.688C197.83 140.942 199.007 145.685 199 150.5L202.417 124.452C201.526 128.822 199.499 133.498 195.573 136.688Z"
        fill="#00008D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M202.786 122C194.998 133.751 181.654 141.5 166.5 141.5C147.374 141.5 131.13 129.156 125.302 112C125.302 112 124.5 114.5 124.5 116.5C124.5 118.5 125 121.077 125 121.077C130.552 138.712 147.032 151.5 166.5 151.5C180.551 151.5 193.046 144.838 200.999 134.5C202.599 131.3 202.857 124.833 202.786 122Z"
        fill="#002FFF"
      />
      <path
        d="M152 98C149.239 98 147 100.686 147 104C147 107.314 149.239 110 152 110C154.761 110 157 107.314 157 104C157 100.686 154.761 98 152 98Z"
        fill="white"
      />
      <path
        d="M156.5 97C154.015 97 152 99.0147 152 101.5C152 103.985 154.015 106 156.5 106C158.985 106 161 103.985 161 101.5C161 99.0147 158.985 97 156.5 97Z"
        fill="#00008D"
      />
      <path
        d="M177 97C174.239 97 172 99.6863 172 103C172 106.314 174.239 109 177 109C179.761 109 182 106.314 182 103C182 99.6863 179.761 97 177 97Z"
        fill="white"
      />
      <path
        d="M180.5 97C178.015 97 176 99.0147 176 101.5C176 103.985 178.015 106 180.5 106C182.985 106 185 103.985 185 101.5C185 99.0147 182.985 97 180.5 97Z"
        fill="#00008D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M145 127H185V176H180V164.443H175V176H170V164.443H165V176H160V164.443H150V176H145V127Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M172.455 137.143C172.677 137.01 172.942 136.968 173.194 137.025C173.446 137.083 173.666 137.236 173.807 137.452L174.797 138.962C174.926 139.159 174.982 139.396 174.953 139.63C174.925 139.864 174.815 140.08 174.642 140.241L174.639 140.245L174.625 140.258L174.568 140.311L174.343 140.526C173.098 141.734 171.891 142.98 170.723 144.262C168.526 146.678 165.917 149.84 164.161 152.908C163.671 153.764 162.474 153.948 161.764 153.209L155.279 146.471C155.186 146.374 155.114 146.26 155.066 146.135C155.018 146.01 154.996 145.876 155.001 145.742C155.005 145.608 155.037 145.477 155.094 145.355C155.15 145.234 155.231 145.125 155.33 145.035L157.29 143.267C157.463 143.112 157.683 143.021 157.915 143.011C158.147 143 158.375 143.071 158.56 143.21L161.869 145.691C167.038 140.594 169.969 138.638 172.455 137.143ZM172.665 139.359C170.375 140.791 167.517 142.868 162.667 147.717C162.497 147.887 162.271 147.99 162.031 148.007C161.791 148.025 161.553 147.954 161.36 147.81L158.018 145.304L157.437 145.828L162.754 151.354C164.6 148.284 167.141 145.228 169.244 142.916C170.352 141.7 171.494 140.515 172.669 139.364L172.665 139.359Z"
        fill="#4CAF50"
      />
      <path
        d="M158 132C162.418 132 166 128.418 166 124C166 119.582 162.418 116 158 116C153.582 116 150 119.582 150 124C150 128.418 153.582 132 158 132Z"
        fill="#00008D"
      />
      <path
        d="M171 131C175.418 131 179 127.418 179 123C179 118.582 175.418 115 171 115C166.582 115 163 118.582 163 123C163 127.418 166.582 131 171 131Z"
        fill="#00008D"
      />
      <path
        d="M160 118.5C160 119.881 165 121 165 121C165 121 170 119.881 170 118.5C170 117.119 167.761 116 165 116C162.239 116 160 117.119 160 118.5Z"
        fill="#FF5252"
      />
      <path d="M94 88.333L69 86V90L94 88.333Z" fill="#94C3FA" />
      <path d="M72 136.2L52 135V138L72 136.2ZM112 109.5L52 106V112L112 109.5Z" fill="#94C3FA" />
      <path
        d="M232.53 44.1402C235.445 43.6462 238.122 45.1032 238.509 47.3932C238.897 49.6832 236.848 51.9392 233.934 52.4332C231.019 52.9262 225.326 51.9802 224.939 49.6902C224.551 47.4002 229.616 44.6332 232.53 44.1402Z"
        fill="#E6EBFF"
      />
      <path
        d="M231.743 55.6061C232.236 58.5211 230.78 61.1981 228.49 61.5861C226.2 61.9731 223.943 59.9241 223.45 57.0101C222.957 54.0951 223.903 48.4031 226.193 48.0151C228.483 47.6281 231.25 52.6921 231.743 55.6061Z"
        fill="#E6EBFF"
      />
      <path
        d="M221.837 56.03C218.922 56.523 216.246 55.067 215.858 52.777C215.471 50.487 217.519 48.231 220.434 47.737C223.348 47.244 229.041 48.19 229.428 50.48C229.816 52.77 224.752 55.537 221.837 56.03Z"
        fill="#E6EBFF"
      />
      <path
        d="M222.624 44.5601C222.131 41.6451 223.587 38.9681 225.877 38.5801C228.167 38.1931 230.424 40.2421 230.917 43.1561C231.41 46.0711 230.464 51.7631 228.174 52.1511C225.884 52.5381 223.117 47.4741 222.624 44.5601Z"
        fill="#E6EBFF"
      />
      <path
        d="M230.256 53.1152C230.6 53.3708 231.086 53.299 231.342 52.955C231.598 52.6109 231.526 52.1249 231.182 51.8693L223.014 45.8028C222.67 45.5472 222.184 45.6189 221.929 45.963C221.673 46.307 221.745 46.7931 222.089 47.0487L230.256 53.1152Z"
        fill="#C7D6FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_6029_8115">
        <rect width="252" height="251" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconMissingDataAnnouncements',
};
</script>
