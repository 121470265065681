<template>
  <div class="payment-page" :class="{ 'px-4': !isInitionalPayment }">
    <iframe v-if="isInitionalPayment" ref="iframe" :src="iframeUrl" class="iframe" />
    <div v-if="isSuccess" class="d-flex flex-column align-center">
      <IconPaymentSuccess class="mb-6" :width="media.isMobile ? 200 : 400" :height="media.isMobile ? 200 : 400" />
      <p class="font-weight-regular text-h4 text-sm-h3 text-center">{{ $t('payment.success_message') }}</p>
    </div>
    <div v-if="isFailed" class="d-flex flex-column align-center">
      <IconPaymentFailed class="mb-6" :width="media.isMobile ? 200 : 400" :height="media.isMobile ? 200 : 400" />
      <p class="font-weight-regular text-h4 text-sm-h3 text-center">{{ $t('payment.failed_message') }}</p>
    </div>
  </div>
</template>

<script>
import { SUCCESS, FAILED, INITIONAL_PAYMENT } from '@/constants/entityStatuses';
import IconPaymentSuccess from '@/components/MissingDataIcons/PaymentSuccess.vue';
import IconPaymentFailed from '@/components/MissingDataIcons/PaymentFailed.vue';

export default {
  name: 'Payment',

  inject: ['media'],

  components: {
    IconPaymentSuccess,
    IconPaymentFailed,
  },
  props: {
    status: {
      type: String,
      default: null,
    },
  },

  computed: {
    isSuccess() {
      return this.status === SUCCESS;
    },
    isFailed() {
      return this.status === FAILED;
    },
    isInitionalPayment() {
      return this.status === INITIONAL_PAYMENT;
    },
    iframeUrl() {
      return `https://secure.ccavenue.ae/transaction/transaction.do?${this.$route.fullPath.split('?')[1]}`;
    },
  },
};
</script>

<style lang="scss">
.payment-page {
  width: 100vw;
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;

  .iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
